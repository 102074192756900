export const cusArrowLeft = {
  prefix: 'fas',
  iconName: 'arrow-left',
  icon: [
    39.3,
    15.1,
    [],
    'f00c',
    'M7.6,15.1L9 13.7 3.8 8.5 39.3 8.5 39.3 6.5 3.8 6.5 9 1.4 7.6 0 0 7.5z',
  ],
};

export const cusArrowRight = {
  prefix: 'fas',
  iconName: 'arrow-right',
  icon: [
    39.3,
    15.1,
    [],
    'f00c',
    'M31.8,0L30.4 1.4 35.5 6.5 0 6.5 0 8.5 35.5 8.5 30.4 13.7 31.8 15.1 39.3 7.5z',
  ],
};
export const faMusicNext = {
  prefix: 'fas',
  iconName: 'music-next',
  icon: [
    18.915,
    21.017,
    [],
    'f00c',
    'M17.86,0c-0.58,0-1.05,0.47-1.05,1.05v7.66L1.57,0.14c-0.51-0.28-1.15-0.1-1.43,0.4C0.05,0.69,0,0.87,0,1.05v18.91c0,0.58,0.47,1.05,1.05,1.05c0,0,0,0,0,0c0.18,0,0.36-0.05,0.51-0.14l15.25-8.58v7.66c-0.03,0.58,0.42,1.07,1,1.1c0.58,0.03,1.07-0.42,1.1-1c0-0.03,0-0.06,0-0.09V1.05C18.91,0.47,18.44,0,17.86,0z',
  ],
};
export const faMusicPrev = {
  prefix: 'fas',
  iconName: 'music-prev',
  icon: [
    18.915,
    21.017,
    [],
    'f00c',
    'M1.05,21.06c0.58,0,1.05-0.47,1.05-1.05c0,0,0,0,0,0v-7.66l15.25,8.58c0.51,0.28,1.15,0.1,1.43-0.4c0.09-0.16,0.13-0.33,0.13-0.51V1.1c0-0.58-0.47-1.05-1.05-1.05c0,0,0,0,0,0    c-0.18,0-0.36,0.05-0.52,0.14L2.1,8.76V1.1c0.03-0.58-0.42-1.07-1-1.1s-1.07,0.42-1.1,1C0,1.04,0,1.07,0,1.1v18.91C0,20.59,0.47,21.06,1.05,21.06z',
  ],
};
