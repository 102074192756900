import React, { useContext } from 'react';

export const staticData = {
  companyName: 'Listen to the Music',
  email: {
    link: 'mailto:kontakt@ltm-band.at',
    text: 'kontakt@ltm-band.at',
  },
  tel: {
    link: 'tel:00436767360340',
    text: '+43 676 73 60 340',
  },
  address: ['An der Walk 24/12', '9020 Klagenfurt am Wörthersee'],
  facebookLink: 'https://www.facebook.com/ltm.band.at/',
  youtubeLink: 'https://www.youtube.com/channel/UCtpQih_bewBBN-p11-xmeAQ',
};

export const staticDataContext = React.createContext(staticData);
export const useStaticData = () => useContext(staticDataContext);
export const StaticDataProvider = ({ children }) => (
  <staticDataContext.Provider value={staticData}>
    {children}
  </staticDataContext.Provider>
);
