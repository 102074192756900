import React from 'react';
import { Link } from 'react-router-dom';
import { useSingleton } from 'services';
import { AwesomeIcon } from 'ui/icons/icons';

export const Footer = () => {
  const { isLoading, data } = useSingleton('home');

  if (isLoading) return null;

  const {
    footerImg,
    footerText: { subtitle, text, title },
    footerContact: { tel, telLink, email, emailLink },
  } = data;
  return (
    <>
      <footer id="kontakt" className="footer row justify-center ">
        <div className="col-ml-9 nop">
          <img className="shadow footer-img" src={footerImg.path} alt="" />
        </div>
        <div className="col-ml-4 footer-content coming-soon">
          <div className="subtitle">Website wird überarbeitet</div>
          <h2>{title}</h2>
          <p>
            Wir sind in kürze wieder erreichbar. In der zwischenzeit kannst du uns
            wie folgt kontaktieren.
          </p>

          <a href={emailLink}>
            <AwesomeIcon icon="envelope" style={{ marginRight: '10px' }} />
            {email}
          </a>
          <br />
          <a
            className="btn btn-accent"
            href="https://www.facebook.com/ltm.band.at/"
            style={{ marginTop: 36, height: 'auto', minHeight: 50, textAlign: 'center' }}
          >
            {/* <AwesomeIcon
              icon={['fab', 'facebook-square']}
              style={{ marginRight: 8, transform: 'translateY(-1px)', }}
            /> */}
            Aktuelle Infos auf Facebook
          </a>
        </div>
        <div className="footer-bottom fw row justify-center flex-align-end white">
          <img className="logo" src="/assets/logo-white.png" alt="logo" />
          <div className="row bottom-content justify-space-around">
            <div>© listen to the music</div>
            <div>
              <Link to="/impressum">Impressum</Link> -{' '}
              <Link to="/datenschutz">Datenschutz</Link>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
// export const Footer = () => {
//   const { isLoading, data } = useSingleton('home');

//   if (isLoading) return null;

//   const {
//     footerImg,
//     footerText: { subtitle, text, title },
//     footerContact: { tel, telLink, email, emailLink },
//   } = data;
//   return (
//     <>
//       <footer id="kontakt" className="footer row justify-center">
//         <div className="col-ml-9 nop">
//           <img className="shadow footer-img" src={footerImg.path} alt="" />
//         </div>
//         <div className="col-ml-4 footer-content">
//           <div className="subtitle">{subtitle}</div>
//           <h2>{title}</h2>
//           <p>{text}</p>
//           <a href={telLink}>
//             <AwesomeIcon icon="phone" style={{ marginRight: '11px' }} />
//             {tel}
//           </a>
//           <br />
//           <a href={emailLink}>
//             <AwesomeIcon icon="envelope" style={{ marginRight: '10px' }} />
//             {email}
//           </a>
//         </div>
//         <div className="footer-bottom fw row justify-center flex-align-end white">
//           <img className="logo" src="/assets/logo-white.png" alt="logo" />
//           <div className="row bottom-content justify-space-around">
//             <div>© listen to the music</div>
//             <div>
//               <Link to="/impressum">Impressum</Link> -{' '}
//               <Link to="/datenschutz">Datenschutz</Link>
//             </div>
//           </div>
//         </div>
//       </footer>
//     </>
//   );
// };
