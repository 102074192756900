import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  cusArrowRight,
  cusArrowLeft,
  faMusicNext,
  faMusicPrev,
} from './custom-icons';
import {
  faTimes,
  faPhone,
  faEnvelope,
  faHome,
  faGuitar,
  faCalendarAlt,
  faStream,
  faMusic,
  faImages,
  faPlay,
  faPause,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookSquare,
  faInstagram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  cusArrowRight,
  cusArrowLeft,
  faPhone,
  faEnvelope,
  faHome,
  faTimes,
  faGuitar,
  faCalendarAlt,
  faStream,
  faMusic,
  faImages,
  faPlay,
  faPause,
  faMusicNext,
  faMusicPrev,
  faYoutube
);
library.add(faFacebookSquare, faInstagram);
console.log(library);

export const AwesomeIcon = (props) => {
  return <FontAwesomeIcon {...props} icon={props.icon} />;
};
