import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from 'config';

export const useSingleton = (name, query = null) => {
  const queryData = useQuery(query || name, () =>
    axios.get(API_URL.singleton(name)).then(({ data }) => data)
  );
  console.log(queryData);
  return queryData;
};
export const useCollection = (name, options = {}, query = null) => {
  const queryData = useQuery(query || name, () =>
    axios
      .post(API_URL.collection(name), { simple: 1, ...options })
      .then(({ data }) => data)
  );

  return queryData;
};
